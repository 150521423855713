// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

export const APIConstants = {
  CDNAPIPrefix: '',
  APIPrefix: 'https://ryukapi.testbook.com/api/',
  TrackerAPIPrefix:'https://alphatracker.testbook.com/',
  CloudBucketDataPrefix: 'https://cdn.testbook.com/json/',
  CustomerGluWriteKey: 'J9LXqa5WkS82j6M9KTkbT512cc1y1CCN1wF4fNyX',
  NodeAPIPrefix: 'http://localhost:80/api/',
  JarvisAPIPrefix: "https://jarvis.testbook.com/",
  OCRMAPIPrefix: 'https://apiocrm.testbook.com/',
  KEY_TOKEN: 'tb_token',
  APIVersion: '1.2',
  ChatProd: false,
  isProduction:false,
  isProdpointed:false,
  chatCDN:"https://chatcdn.testbook.com/alpha/",
  firebase: {
    apiKey: "AIzaSyD4Fdv5p4-gm0X4B4YkQ_MNI7mXtBlJORI",
    authDomain: "testbook-alpha.firebaseapp.com",
    databaseURL: "https://testbook-alpha-deabc.firebaseio.com",
    projectId: "testbook-alpha",
    storageBucket: "testbook-alpha.appspot.com",
    messagingSenderId: "91425948661",
    appId: "1:91425948661:web:7833d6c897f7d4391e56bc"
  },
  livepollingDB:{
    databaseURL: "https://testbook-alpha-deabc.firebaseio.com",
  },
  chatDb:{
    databaseURL: "https://testbook-alpha-deabc.firebaseio.com/"
  },
  pdfDownloaderUrl: 'https://blog-jon.testbook.com/blog/wp-pdf.php',
  pwbApiEndpoint: 'https://pwbapi.testbook.com/'
}
