import {baseModel, setupModel} from "@models/types";
import {arrayModel, objModel, propertyMaps, SourceProjectionMapper} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";
import {Validator as _} from "../../../shared/utility/validator";
import { PurchaseInfo } from "../purchaseInfo";

export class _AttemptedTest extends baseModel{

    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('isAttempted', _.bool)
    public isAttempted;

    @propertyMaps('rank', _.num)
    public rank;

    @propertyMaps('totalStudents', _.num)
    public totalStudents;

    @propertyMaps('markScored', _.num)
    public markScored;

    @propertyMaps('attemptedOn', _.str)
    public attemptedOn;

    @propertyMaps('correct', _.num)
    public correct;

    @propertyMaps('attemptedQuestions', _.num)
    public attemptedQuestions;

    @propertyMaps('timeTaken', _.num)
    public timeTaken;

    @propertyMaps('isReattemptable', _.bool)
    public isReattemptable;

    @propertyMaps('maxAllowedAttempts', _.num)
    public maxAllowedAttempts;

    @propertyMaps('attemptNo', _.num)
    public attemptNo;

    @propertyMaps('isResumableForNextAttempt', _.bool)
    public isResumableForNextAttempt;

    public attemptedOnStr;


}
setupModel(_AttemptedTest,{type:'test'});

export class RegisterModuleParams extends baseModel{

    @propertyMaps('mtype')
    public mtype;

    @propertyMaps('mid')
    public mid;

    @propertyMaps('pageUrl')
    public pageUrl?: string;

}
setupModel(RegisterModuleParams,{});


export class RegisterModuleApi{
    static apiEndpoint = 'v1/students/module-register';

    static  __projection = 1;

    static apiCall(network:Network,params:any){
        return network.post(RegisterModuleApi.apiEndpoint,params.request,{language:'English',mid:params.id,__projection:RegisterModuleApi.__projection});
    }
}

export class TestDetails{
    @propertyMaps('id')
    public id;

    @propertyMaps('target')
    public target;

    @propertyMaps('title')
    public title;

    @propertyMaps('isScholarship')
    public isScholarship;

    @propertyMaps('scholarshipId')
    public scholarshipId;

    @propertyMaps('purchaseInfo',arrayModel(PurchaseInfo),PurchaseInfo)
    public purchaseInfo;
}
setupModel(TestDetails,{})

export class TestState{
    @propertyMaps('isASM',  _.bool)
    public isASM;

    @propertyMaps('isActive', _.bool)
    public isActive;

    @propertyMaps('isRefresh', _.bool)
    public isRefresh;

    @propertyMaps('hasChosenSections', _.bool)
    public hasChosenSections;

    @propertyMaps('remT', _.num)
    public remT;

    @propertyMaps('isAnalysisGenerated', _.bool)
    public isAnalysisGenerated;
}
setupModel(TestState,{})

export class TestDetailsWrapper{
    @propertyMaps('testDetails',objModel(TestDetails),TestDetails)
    public testDetails;
}
setupModel(TestDetailsWrapper,{})

export class GetTestDetailsApi{
    static apiEndpoint = 'v1/tests/{testId}/overview';

    static projection;
    static get  __projection(){ 
        if(!GetTestDetailsApi.projection){
            GetTestDetailsApi.projection = JSON.stringify(new SourceProjectionMapper(TestDetailsWrapper).map());
        }
        return GetTestDetailsApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetTestDetailsApi.apiEndpoint.replace('{testId}',params.testId),{__projection:GetTestDetailsApi.__projection });
    }
}

export class GetTestStateApi{
    static apiEndpoint = 'v2/tests/{testId}/state';

    static projection;
    static get  __projection(){ 
        if(!GetTestStateApi.projection){
            GetTestStateApi.projection = JSON.stringify(new SourceProjectionMapper(TestState).map());
        }
        return GetTestStateApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetTestStateApi.apiEndpoint.replace('{testId}',params.testId),{__projection:GetTestStateApi.__projection,beforeServe:params.beforeServe });
    }
}

